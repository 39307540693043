.modal-class > .ant-modal {
  position: relative !important;
  left: 500px !important;
  top: 400px !important;
}

.modal-class {
  position: relative;

  /* left : 700px !important; */
  /* border : 5px solid red; */
}

.expohall_sponsorship_saveBtn {
  width: 100px;
  height: 40px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
}

.expohall_sponsorship_cancelBtn {
  width: 100px;
  height: 40px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  color: #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.expohall_sponsorship_cancelBtn:hover {
  width: 100px;
  height: 40px;

  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
}

/* Expo Hall Open Section css */

.expohall_form {
  /* margin-top: 40px;
  padding-right: 49px; */
  padding: 5%;
  height: auto;
}

.expohall_row {
  margin-bottom: 30px;
  height: auto;
}

.expohall_label {
  display: flex;
  justify-content: flex-end;

  padding-right: 30px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
}

.expohall_label p {
  height: 23px;
  margin-top: 3px;
}

.expohall_inputDescription {
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

.expohallcancelBtn {
  width: 90px;
  height: 40px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.expoHallSaveBtn {
  width: 90px;
  height: 40px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

/* End of Expo Hall Open Section Css */

/* Admin Satellite Section Start*/
.adminSatellite_section_1_form {
  margin-top: 40px;
  padding-right: 49px;
  border-bottom: 1px solid #eae4f2;
  height: auto;
}

.modal_row {
  margin-bottom: 15px;
  height: auto;
}

.adminSatellite_fields {
  display: flex;
  align-items: center;
}

/* .modal_field_label p {
  height: 23px;
  margin-top: 5px;
} */

.modal_field_label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
}

.invite_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminSatellite_inputTitle {
  height: 41px;
  width: 100%;
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

.adminSatellite_inputDescription {
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

.adminSatellite_section_1_downloadBtn {
  width: 200px;
  height: 41px;
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  background-image: url("/src/assets/svgs/download.svg");
  background-position: 20px;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_section_1_downloadBtn:hover {
  width: 200px;
  height: 41px;
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  background-image: url("/src/assets/svgs/download.svg");
  background-position: 20px;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_downloadIcon {
  margin-right: 10px;
}

.adminSatellite_date_time {
  width: 100%;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_section_2_form {
  margin-top: 30px;
  padding-right: 49px;
  border-bottom: 1px solid #eae4f2;
  padding-bottom: 30px;
  padding-left: 30px;
  height: auto;
}

.adminSatellite_section_2_title {
  width: 100%;
  height: 29px;

  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_section_2_info {
  width: 100%;
  height: 23px;
  text-align: left;

  font: normal normal normal 16px/22px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_delete_icon {
  width: 16px;
  height: 20px;
}

.adminSatellite_section_2_brand {
  width: 100%;
  height: 102px;
  margin-bottom: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_section_2_brand_row1 {
  height: 40px;
  align-items: center;
  padding-left: 7px;
  padding-right: 19.79px;
}

.adminSatellite_section_2_brand_name {
  font: normal normal normal 16px/20px Jost;
  display: inline;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.brand_row {
  border: none !important;
  border-radius: 0px 0px 5px 5px;
}

.brand_row .ant-table-cell {
  border-bottom: none !important;
}

.border_none {
  border: none;
}

.border_none > .ant-table-cell {
  border: none;
}

.brand_invite_row {
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  background: #fbfbfb 0% 0% no-repeat padding-box;

  opacity: 1;
}

.adminSatellite_section_2_select1 {
  width: 92%;
  height: 41px;
  margin-left: 10px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_search_agent {
  width: 100%;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
  opacity: 1;
}

.adminSatellite_section_2_select2 {
  width: 92%;
  height: 41px;
  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_section_2_pending {
  width: 97px;
  height: 30px;
  letter-spacing: 0.7px;
  color: #f3871a !important;
  text-transform: uppercase;
  background: #f3871a1a 0% 0% no-repeat padding-box !important;
  border: 1px solid #f3871a !important;
  border-radius: 15px;
  opacity: 1;
}

.adminSatellite_section2_brand_border_Red {
  border: 1px solid #ef0855;
  color: #ef0855;
}

.adminSatellite_section_2_reject {
  width: 97px;
  height: 30px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef08551a 0% 0% no-repeat padding-box !important;
  border: 1px solid #ef0855 !important;
  border-radius: 15px;
  opacity: 1;
  font: normal normal medium 14px/16px Jost;
  letter-spacing: 0.7px;
  color: #ef0855 !important;
  text-transform: uppercase;
  opacity: 1;
}

.adminSatellite_section2_brand_box2 {
  height: 41px;
  /* UI Properties */

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;

  display: flex;
  padding-left: 20px;
  align-items: center;
  opacity: 1;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
}

.adminSatellite_section_2_accepted {
  width: 97px;
  height: 30px;
  background: #4dcc591a 0% 0% no-repeat padding-box !important;
  border: 1px solid #4dcc59 !important;
  border-radius: 15px;
  opacity: 1;
  font: normal normal medium 14px/16px Jost;
  letter-spacing: 0.7px;
  color: #4dcc59 !important;
  text-transform: uppercase;
}

.adminSatellite_search_agent .ant-select-selector .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.adminSatellite_search_agent .ant-select-selection-placeholder {
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.adminSatellite_section_2_invite {
  width: 82px;
  height: 41px;

  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;

  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
}

.adminSatellite_addNewBrandTitle {
  width: 142px;
}

.addBrandTitle {
  height: 26px;
  text-align: right;
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_selectBrand {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_selectZone {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_section_2_savechanges {
  width: 168px;
  height: 41px;
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
}

/* adminSatellite_Section_3_source */
.adminSatellite_section_3_form {
  margin-top: 30px;
  padding-right: 49px;
  padding-bottom: 30px;
  padding-left: 30px;
  height: auto;
}

.adminSatellite_section_3_title {
  width: 100%;
  height: 29px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_section_3_info {
  font: normal normal normal 16px/22px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_or {
  font: normal normal normal 12px/60px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_section_3_linkInput {
  width: 100%;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_section_3_inputPassword {
  width: 100%;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_section_4_form {
  margin-top: 30px;
  padding-right: 49px;
  padding-bottom: 30px;
  padding-left: 30px;
  border-bottom: 1px solid #eae4f2;
  height: auto;
}

.adminSatellite_section_4_title {
  height: 29px;
  /* UI Properties */

  text-align: left;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_section_4_heading {
  height: 26px;
  /* UI Properties */
  text-align: left;
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_section_4_select {
  width: 100%;
  height: 41px;
  /* UI Properties */
  /* overflow: auto; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 5px;
  opacity: 1;
}

.dropdown_container {
  width: 100%;
  height: 245px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  opacity: 1;
}

.dropdown_options {
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/23px Jost;
  letter-spacing: 0px;
  color: #000000;
  border-radius: 4px;
  opacity: 1;
}

.adminSatellite_section_4_taggedagents_item {
  width: 100%;
  min-height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 9.86px;
  padding-left: 10px;
  font: normal normal normal 16px/23px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

/* adminSatellite_Section 5 Message Board */
.adminSatellite_section_5_form {
  margin-top: 30px;
  padding-right: 49px;
  padding-bottom: 30px;
  padding-left: 30px;
  border-bottom: 1px solid #eae4f2;

  height: auto;
}

.adminSatellite_section_5_title {
  width: 100%;
  height: 29px;

  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_section_5_info {
  display: inline-block;
  margin: auto 15px;
  font: normal normal medium 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_section_5_taggedagents_container {
  width: 100%;
  height: 245px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  opacity: 1;
}

/* adminSatellite_Section 6 Media Assets */
.adminSatellite_section_6_form {
  margin-top: 30px;
  padding-right: 49px;
  padding-bottom: 30px;
  padding-left: 30px;
  border-bottom: 1px solid #eae4f2;

  height: auto;
}

.adminSatellite_section_6_title {
  width: 100%;
  height: 29px;

  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_section_6_info {
  display: inline-block;
  white-space: nowrap;
  font: normal normal normal 16px/22px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_section_6_fileUploadBtn {
  width: 178px;

  height: 41px;
  /* UI Properties */

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ef0855;

  opacity: 1;
}

.adminSatellite_section_6_fileUploadIcon {
  margin-right: 10px;
}

/* adminSatellite_Section 7 Restrict Access */
.adminSatellite_section_7_form {
  margin-top: 30px;
  padding-right: 49px;
  padding-bottom: 30px;
  padding-left: 30px;
  border-bottom: 1px solid #eae4f2;

  height: auto;
}

.adminSatellite_section_7_title {
  width: 100%;
  height: 29px;

  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_selectUserList {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_searchForUser {
  width: 100%;
  height: 41px;
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.adminSatellite_userListTitle {
  width: 100%;
  height: 26px;
  text-align: left;
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_section7_box1 {
  width: 100%;
  height: 245px;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  overflow-y: scroll;
  opacity: 1;
}

.adminSatellite_list_container {
  padding: 10px;
  min-height: 61px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
}

.adminSatellite_item_blacklist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 3px;
  opacity: 1;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal normal 16px/23px Jost;
  letter-spacing: 0px;
  color: #ef0855;
}

.adminSatellite_item_whitelist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 3px;
  opacity: 1;
  font: normal normal normal 16px/23px Jost;
  letter-spacing: 0px;
  color: #000000;
}

.adminSatellite_section7_brand_box1 {
  width: 100%;
  height: 61px;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  overflow-y: scroll;
  opacity: 1;
}
.adminSatellite_section7_brand_box2 {
  width: 100%;
  height: 61px;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  overflow-y: scroll;
  opacity: 1;
}

.adminSatellite_noOneSelected {
  display: inline;
  color: #000000;
  margin: 0;
  opacity: 0.5;
}

.adminSatellite_section7_country_box1 {
  width: 100%;
  height: 61px;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  overflow-y: scroll;
  opacity: 1;
}
.adminSatellite_section7_country_box2 {
  width: 100%;
  height: 61px;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  overflow-y: scroll;
  opacity: 1;
}

.adminSatellite_section7_job_box1 {
  width: 100%;
  height: 61px;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  overflow-y: scroll;
  opacity: 1;
}

.adminSatellite_section7_job_box2 {
  width: 100%;
  height: 61px;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  overflow-y: scroll;
  opacity: 1;
}

/* adminSatellite_Section 8 Previous & Pending Invitees */

.adminSatellite_section_8_form {
  margin-top: 30px;
  padding-right: 49px;
  padding-bottom: 30px;
  padding-left: 30px;
  border-bottom: 1px solid #eae4f2;

  height: auto;
}

.adminSatellite_section_8_title {
  width: 100%;
  height: 29px;

  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_section_8_previousInvitedBtn {
  width: 324px;
  height: 41px;
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
  background-image: url("/src/assets/svgs/eye-line-white.svg");
  background-position: 15px;
  white-space: nowrap;
  opacity: 1;
}

.adminSatellite_eyeViewBtn {
  display: flex;

  position: relative;
}

.adminSatellite_eyeViewIcon {
  position: absolute;
  top: 10px;
  left: 22px;
}

.adminSatellite_section_8_heading {
  height: 26px;
  text-align: left;
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_date_time {
  width: 305px;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminSatellite_section_8_viewListBtn {
  width: 159px;
  height: 41px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  /* background-image: url("/src/assets/svgs/eye-line.svg"); */
  /* background-position: 20px; */
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ef0855;
}

.adminSatellite_section_8_viewListBtn:hover {
  width: 159px;
  height: 41px;

  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  /* background-image: url("/src/assets/svgs/eye-line-white.svg"); */
  /* background-position: 20px; */
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
}

.adminSatellite_eyeViewIcon {
  position: absolute;
  top: 10px;
  left: 22px;
}

/* adminSatellite_Section 9 New Invitees */

.adminSatellite_section_9_form {
  margin-top: 30px;
  padding-right: 49px;
  padding-left: 30px;
  height: auto;
  border-bottom: 1px solid #eae4f2;
}

.adminSatellite_section_9_heading {
  width: 125px;
  height: 26px;
  text-align: left;

  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminSatellite_attendeesSelectedText {
  height: 23px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal normal 16px/22px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_section_9_csvUploadBtn {
  width: 184px;
  height: 41px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
  color: var(--unnamed-color-ef0855);
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ef0855;
}

.adminSatellite_cloudComputingIcon {
  margin-right: 10px;
}

.adminSatellite_section_10_form {
  margin-top: 30px;
  padding-right: 49px;
  padding-bottom: 30px;
  padding-left: 30px;
  height: auto;
}

.section_title {
  width: 100%;
  height: 29px;
  padding-left: 30px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  opacity: 1;
}

.adminSatellite_section_9_title {
  width: 100%;
  height: 29px;
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.section_black_title {
  color: #000000;
}

.section_theme_title {
  color: #ef0855;
}

/* Modal Footer */
.adminSatellite_cancelBtn {
  width: 112px;
  height: 53px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  color: var(--unnamed-color-ef0855);
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ef0855;
  opacity: 1;
}

.adminSatellite_saveBtn {
  width: 316px;
  height: 53px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
}
/* Admin Satellite Section End */

/* Admin Networking Section Start */
.adminNetworking_section_1_form {
  margin-top: 40px;
  padding-right: 49px;
  height: auto;
}

.adminNetworking_fields {
  display: flex;
  align-items: center;
}

.adminNetworking_inputTitle {
  height: 41px;
  width: 100%;
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

.adminNetworking_inputDescription {
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

.adminNetworking_section_1_inputSeat {
  width: 100%;
}

.adminNetworking_section_1_downloadBtn {
  width: 200px;
  height: 41px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  white-space: nowrap;
  background-image: url("/src/assets/svgs/download.svg");
  background-position: 30px;
  padding-left: 1.5em;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.adminNetworking_section_1_downloadBtn:hover {
  width: 200px;
  height: 41px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  white-space: nowrap;
  background-image: url("/src/assets/svgs/download.svg");
  background-position: 30px;
  padding-left: 1.5em;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

/* Section-2 */
.adminNetworking_section_2_form {
  margin-top: 30px;
  padding-right: 49px;

  height: auto;
}

.adminNetworking_section_2_title {
  width: 100%;
  height: 29px;
  padding-left: 30px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminNetworking_section_2_info {
  width: 100%;
  height: 23px;
  text-align: left;
  padding-left: 30px;
  font: normal normal normal 16px/22px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminNetworking_delete_icon {
  width: 16px;
  height: 20px;
}

.adminNetworking_addBrand {
  width: 174px;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
  letter-spacing: 0.8px;
  color: #ef0855;
}

.adminNetworking_addBrand:hover {
  width: 174px;
  height: 41px;
  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
  letter-spacing: 0.8px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ef0855;

  color: #ffffff;
}

.adminNetworking_addNewBrandTitle {
  width: 142px;
  height: 26px;
  text-align: right;
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminNetworking_selectBrand {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminNetworking_selectZone {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminNetworking_section_2_savechanges {
  width: 168px;
  height: 53px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

/* Section-3 */
.adminNetworking_section_3_form {
  margin-top: 30px;
  padding-right: 49px;

  height: auto;
}

.adminNetworking_section_3_title {
  width: 100%;
  height: 100%;
  padding-left: 30px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminNetworking_section_3_info {
  width: 100%;
  height: 100%;
  text-align: left;
  padding-left: 30px;
  font: normal normal normal 16px/22px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminNetworking_selectUserList {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminNetworking_searchForUser {
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
}

.adminNetworking_userListTitle {
  width: 100%;
  height: 26px;
  text-align: left;
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminNetworking_section3_box1 {
  width: 100%;
  height: 245px;
  overflow-y: scroll;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminNetworking_list_container {
  padding: 10px;
  min-height: 61px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
}

.adminNetworking_item_blacklist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 3px;
  opacity: 1;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal normal 16px/23px Jost;
  letter-spacing: 0px;
  color: #ef0855;
}

.adminNetworking_item_whitelist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 3px;
  opacity: 1;
  font: normal normal normal 16px/23px Jost;
  letter-spacing: 0px;
  color: #000000;
}

.adminNetworking_section3_brand_box1 {
  width: 100%;
  height: 61px;
  overflow-y: scroll;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminNetworking_section3_country_box1 {
  width: 100%;
  height: 61px;
  overflow-y: scroll;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminNetworking_section3_job_box1 {
  width: 100%;
  height: 61px;
  overflow-y: scroll;
  background: #fff1f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.noOneSelected {
  display: inline;
  color: #000000;
  margin: 0;
  opacity: 0.5;
}

.adminNetworking_section_4_form {
  margin-top: 30px;
  padding-right: 49px;

  height: auto;
}

.adminNetworking_section_4_title {
  width: 100%;
  height: 29px;
  padding-left: 30px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminNetworking_eyeViewIcon {
  position: absolute;
  top: 10px;
  left: 22px;
}

.adminNetworking_date_time {
  width: 305px;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.adminNetworking_eyeViewIcon {
  position: absolute;
  top: 10px;
  left: 22px;
}

/* Section 5 */

.adminNetworking_section_5_form {
  margin-top: 30px;
  padding-right: 49px;

  height: auto;
}

.adminNetworking_section_5_title {
  width: 100%;
  height: 29px;
  padding-left: 30px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminNetworking_section_5_heading {
  width: 125px;
  height: 26px;
  text-align: left;

  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.adminNetworking_attendeesSelectedText {
  height: 23px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal normal 16px/22px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminNetworking_section_5_csvUploadBtn {
  width: 184px;
  height: 41px;
  text-align: center;
  padding-left: 30px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;

  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ef0855;
  background-image: url("/src//assets/svgs/cloud-computing.svg");
  background-position: 20px;
}

.adminNetworking_cloudComputingIcon {
  margin-right: 10px;
}

.adminNetworking_section_6_form {
  margin-top: 30px;
  padding-right: 49px;

  height: auto;
}

.adminNetworking_section_6_title {
  width: 100%;
  height: 29px;
  padding-left: 30px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminNetworking_section_7_form {
  margin-top: 30px;
  padding-right: 49px;

  height: auto;
}

.adminNetworking_section_7_title {
  width: 100%;
  height: 29px;
  padding-left: 30px;
  color: var(--unnamed-color-ef0855);
  text-align: left;
  font: normal normal medium 20px/16px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.adminNetworking_or {
  color: var(--unnamed-color-ef0855);
  text-align: right;
  font: normal normal normal 12px/60px Jost;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

/* Modal Footer */
.adminNetworking_cancelBtn {
  width: 112px;
  height: 53px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  color: var(--unnamed-color-ef0855);
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ef0855;
  opacity: 1;
}

.adminNetworking_saveBtn {
  width: 316px;
  height: 53px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
}

/* Admin Networking Section End */

/* TickerConfiguration  Start */

.ticker_configuration_form {
  margin-top: 40px;
  padding-right: 49px;
  height: auto;
}

.ticker_configuration_row {
  margin-bottom: 30px;
  height: auto;
}
.ticker_configuration_cancelBtn {
  width: 100px;
  height: 45px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.ticker_configuration_SaveBtn {
  width: 100px;
  height: 45px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.ticker_configuration_label {
  display: flex;
  justify-content: flex-end;

  padding-right: 30px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
}

.ticker_configuration_label p {
  height: 23px;
  margin-top: 3px;
}

.ticker_configuration_timepicker {
  height: 41px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}
.ticker_configuration_selectBrand {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;

  letter-spacing: 0px;
  color: #000000;

  opacity: 1;
}

.ticker_configuration_inputTitle {
  height: 41px;
  width: 100%;
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

.ticker_configuration_inputDescription {
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

/* TickerConfiguration  End */

/* Social Wall Sponsorship Start */

.socialwallsponsorship_form {
  margin-top: 40px;
  padding-right: 49px;
  height: auto;
}

.socialwallsponsorship_row {
  margin-bottom: 30px;
  height: auto;
}
.socialwallsponsorship_cancelBtn {
  width: 100px;
  height: 45px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.socialwallsponsorship_SaveBtn {
  width: 100px;
  height: 45px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.socialwallsponsorship_label {
  display: flex;
  justify-content: flex-end;

  padding-right: 30px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
}

.socialwallsponsorship_label p {
  height: 23px;
  margin-top: 3px;
}

.socialwallsponsorship_timepicker {
  height: 41px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}
.socialwallsponsorship_selectBrand {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;

  letter-spacing: 0px;
  color: #000000;

  opacity: 1;
}

.socialwallsponsorship_inputTitle {
  height: 41px;
  width: 100%;
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

/* Concourse Sponsorship Start */

.concourse_sponsors_form {
  margin-top: 40px;
  padding-right: 49px;
  height: auto;
}

.concourse_sponsors_row {
  margin-bottom: 30px;
  height: auto;
}
.concourse_sponsors_cancelBtn {
  width: 100px;
  height: 45px;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.concourse_sponsors_SaveBtn {
  width: 100px;
  height: 45px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.concourse_sponsors_label {
  display: flex;
  justify-content: flex-end;

  padding-right: 30px;

  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
}

.concourse_sponsors_label p {
  height: 23px;
  margin-top: 3px;
}

.concourse_sponsors_timepicker {
  height: 41px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}
.concourse_sponsors_selectBrand {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;

  letter-spacing: 0px;
  color: #000000;

  opacity: 1;
}

.concourse_sponsors_inputTitle {
  height: 41px;
  width: 100%;
  text-align: left;
  font: normal normal normal 16px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
}

/* Concourse Sponsorship End */

/* Admin Popups Start */

.popup_1_modal_circle {
  width: 38px;
  height: 38px;
  margin: 0;
  border-radius: 100%;

  /* UI Properties */
  background: #1428a0 0% 0% no-repeat padding-box;
  opacity: 0.7;
}

.admin_popup_icons {
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.adminpopup2accept {
  width: 100px;
  height: 40px;
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  font: normal normal medium 16px/16px Jost;
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
}

.adminpopup2reject {
  width: 100px;
  height: 40px;
  margin-left: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  color: #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.btnIconSm {
  border: #f0f0f0 1px solid !important;
  background-color: white !important;
}

.mx-2 {
  margin-left: 2%;
  margin-right: 2%;
}

.btnIconSm:hover {
  border: #ef0855 1px solid !important;
  color: #ef0855 !important;
}

/* .btn-grey {
  border: 1px solid #7f7f7f !important;
  color: #7f7f7f !important;
}

.btn-grey > svg {
  fill: #7f7f7f;
}

.btn-grey:hover,
.btn-grey-box:hover,
.btn-grey:hover > svg {
  background-color: #ef0855 !important;
  border: 1px solid #ef0855 !important;
  fill: #ef0855 !important;
  color: white !important;
} */

.btn-Border-pink {
  border: 1px solid #ef0855;
  color: #ef0855;
}
.btn-Border-pink:hover,
.btn-Border-pink-box:hover {
  background-color: #ef0855;
  color: white;
}

.btn-Border-pink-box {
  width: 80%;
  border: 1px solid #ef0855;
  color: #ef0855;
}

.btn-Border-pink-box:hover .ant-typography-ellipsis-single-line {
  color: white;
}

.dx-scheduler-header-panel-cell.dx-scheduler-cell-sizes-horizontal {
  display: none !important;
}

.ant-table-thead > tr > th {
  background-color: white;
}

.ant-table-cell {
  padding: 0% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}

.hall_header_title {
  color: #ef0855;
  font-size: 15;
}

.ant-table-header {
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.h-padding1 {
  padding-right: 3% !important;
  padding-left: 3% !important;
}

.expo_hall_open {
  background-color: #f35187;
}

.button-pink {
  border: 1px solid #ef0855;
  background-color: white;
  color: #ef0855;
}
.button-pink:hover {
  background-color: #ef0855;
  color: white;
}

.arrow_righ_left {
  color: #939393;
}

.arrow_righ_left:hover {
  color: #ef0855;
}

.button-pink-filter {
  background-color: #ef0855;
  color: white;
  border: none;
}
.button-pink-filter:hover {
  background-color: #ef0855;
  color: white;
  border: none;
}

.event_calender_td,
.timeslots {
  border-right: 1px solid #dee2e6 !important;
}

.timeslot_sticky {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 5;
  background: #fff;
}

.current_time_line {
  height: 1px;
  background: #e50e5c;
  position: absolute;
  width: 100%;
}

.cell_bgColor {
  background-color: #fbfbfb !important;
}

.event_table_wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
  height: 575px;
}

.event_table_topBar {
  background-color: white;
  width: 100%;
  height: 75px;
}

.event_table_leftBar {
  background-color: white;
  width: 10%;
  /* border-right: 1px solid #dee2e6; */
  box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 15%) !important;
}

.event_table {
  /* overflow: scroll scroll; */
  max-height: 240px;
  background-color: white;
  min-width: 100%;
  table-layout: fixed;
  width: 500px;
}

.event_table_header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 200;
  background: #fff;
}

.event_table_header_row {
  box-shadow: 0 0.4rem 1rem rgb(0 0 0 / 15%) !important;
}

/* table tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

table thead,
table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
} */

.event_table_body {
  z-index: 1;
  max-height: 300px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white !important;
}

.event_header_left {
  width: 100px;
}

.event_header_double {
  width: 234px;
}

.event_header_single {
  width: 130px;
}

.box_title {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
}

.edit_icon {
  height: 17px;
  width: 17px;
  color: #7f7f7f;
  fill: #7f7f7f;
  background-image: url("/src/assets/svgs/edit.svg");
  padding-left: 2px;
}
.edit_icon:hover {
  fill: rgb(238, 37, 70);
  background-image: url("/src/assets/svgs/edit-red.svg");
}

.popover_header {
  text-align: center;
}

.popover_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event_calender_searchbar {
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  padding-left: 10px;
}

.event_calender_small_button {
  width: 36px;
  justify-content: center;
  display: flex;
}

.blank_button {
  height: 1.5em !important;
  border: transparent !important;
  background-color: transparent !important;
  cursor: none !important;
}

.box_logo {
  margin-top: 1%;
  height: 50px;
  width: 50px;
}

.box_mini_icons {
  height: 30px;
}

/* Css For View Pop-ups */

/*  Auditorium Event */
.audiEvent_downloadBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/downloadRed.svg");
  background-position: 15px;
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.audiEvent_downloadBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/download.svg");
  background-position: 15px;
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.audiEvent_brand {
  background-color: #f1f1f1;
}

.audiEvent_icons {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audiEvent_deleteBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/bin.svg");
  color: #ef0855;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.audiEvent_deleteBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/binwhite.svg");
  color: #ffffff;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.audiEvent_joinBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/booth-red.svg");
  color: #ef0855;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.audiEvent_joinBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/booth-white.svg");
  color: #ffffff;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

/* NetworkingSession.css */
/* Start of Networking Session */

.networksess_card_container {
  background-color: #fbfbfb;
  border: 1px solid #e8e4ee;
}

.networkingsess_downloadBtn {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/downloadRed.svg");
  background-position: 20px;
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.networkingsess_downloadBtn:hover {
  width: 180px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/download.svg");
  background-position: 20px;
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.networkingsess_audiBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/booth-red.svg");
  color: #ef0855;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.networkingsess_audiBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/booth-white.svg");
  color: #ffffff;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.networkingsess_pdfBtn {
  width: 219px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/downloadRed.svg");
  background-position: 30px;
  color: #ef0855;

  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.networkingsess_pdfBtn:hover {
  width: 219px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/download.svg");
  background-position: 30px;

  color: #ffffff;
  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.networkingsess_deleteBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/bin.svg");
  color: #ef0855;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.networkingsess_deleteBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/binwhite.svg");
  color: #ffffff;
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.message_modal.ant-modal.ant-modal-content {
  min-width: 350px !important;
}
