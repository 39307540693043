.brands_header {
    width: 100%;
    height: 100px;
  
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000000d;
    border-radius: 5px;
    opacity: 1;
  }
  
  .brands_input_search {
    width: 100%;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
  
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
  }
  
  .brands_event_search {
    width: 100%;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
  
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
  }
  .brands_searchicon {
    position: absolute;
    top: 12px;
    left: 10px;
  }
  
  .brands_header_btns {
    width: 100px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
  
    box-shadow: 0px 3px 1px #ef085533;
    border: 1px solid #eae4f2;
    letter-spacing: 0.8px;
    color: #ef0855;
    text-transform: uppercase;
    border-radius: 5px;
  }
  
  .brands_cards_container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 5px;
    column-gap: 25px;
    padding-left: 20px;
  }
  
  .brands_card {
    width: 30%;
    height: auto;
    min-height: 190px;
    /* display: flex; */
    overflow-x: hidden;
    word-wrap: break-word;
    padding: 10px;
    margin-bottom: 20px;
    background: #f1f1f1 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .brands_card_header {
    display: flex;
    column-gap: 10px;
  }
  
  .brands_card_img_container {
    width: 100px;
    height: 100px;
    /* UI Properties */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    opacity: 1;
  }
  
  .brands_card_img {
    width: 100px;
    height: 100px;
  }
  .brands_card_header_title {
    margin: 0;
  }
  
  .brands_card_header_sub_title {
    /* UI Properties */
    text-align: right;
    font: normal normal normal 12px/16px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  
  .brands_card_header_booth {
    margin-top: 5px;
    font-size: 0.7rem;
    color: #ef0855;
    display: flex;
    column-gap: 6px;
  }
  
  .brands_booth_icon {
    width: 15px;
    height: 15px;
  }
  .brands_card_header_icon_container {
    margin-top: 9px;
    display: flex;
    column-gap: 16px;
  }
  
  .brands_card_header_icons_bg {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eae4f2;
    border-radius: 5px;
    opacity: 1;
  }
  
  .brands_card_header_icon {
    width: 0.9em;
    height: 0.9em;
  }
  
  .brands_card_body {
    margin-top: 5px;
  
    font-size: 0.8rem;
  }
  
  .brands_card_footer_links {
    display: flex;
    align-items: center;
  
    column-gap: 10px;
  }
  
  @media (max-width: 1200px) {
    .brands_card {
      width: 45%;
      height: 45%;
    }
  }
  
  @media (max-width: 1000px) {
    .brands_card {
      width: 80%;
      height: 80%;
    }
  }
  