.helpandsupp_sider {
  width: 40%;
  z-index: 3;
  padding: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 5px 0px 0px 5px;
}

.helpandsupp_header {
  height: 80px;
}

.helpandsupp_chatarea {
  height: 80%;
}

.sendmessage_input {
  width: 95%;
  height: 41px;

  /* UI Properties */
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
}

.message_sendBtn {
  width: 3rem;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;

  border-radius: 5px;
}

.message_sendBtn:hover {
  width: 3rem;
  height: 41px;
  background: #ef0855 0% 0% no-repeat padding-box;

  border-radius: 5px;
}

.message_sendBtn:hover svg path {
  fill: #ffffff;
}

.chat-sender {
  background-color: #fff1f5;
  border: 1px solid #ffb2cc;
  border-radius: 10px 10px 10px 0px;
}

.chat-reciever {
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  border-radius: 10px 10px 10px 0px;
}
