.ms_page_container {
  width: 100%;
  min-height: 75vh;
  height: auto;
  background-color: #ffffff;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  column-gap: 30px;
  box-shadow: 0px 5px 10px #0000000d;
}

.ms_img_container {
  width: 25%;
  height: 268px;
  border: 1px solid #cccccc;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.ms_zero_hero {
  color: #999999;
}

.ms_page_content {
  width: 70%;
}

.ms_page_title {
  font-size: 18px;
  letter-spacing: 0px;
  color: #ef0855;
  opacity: 1;
}

.ms_page_description {
  /* font: normal normal normal 16px/22px Jost;
   */
  font-size: 14px;
  min-height: 125px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.ms_interact_live {
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.ms_btns_container {
  display: flex;
  width: 100%;
  column-gap: 10px;
  margin-bottom: 30px;
}

.ms_btn {
  display: flex;
  column-gap: 5px;
  align-items: center;
  margin-bottom: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  letter-spacing: 0.8px;
  color: #ef0855;
  opacity: 1;
}

.ms_external_links {
  font: normal normal medium 18px/60px Jost;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.ms_links {
  width: 100%;
  display: flex;
  column-gap: 8px;
  margin-bottom: 30px;
}

.ms_assets_card {
  width: 300px;
  height: 135px;
  padding: 20px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  border-radius: 5px;
  opacity: 1;
}

.ms_card_upper_section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ms_card_lower_section {
  display: flex;
  /* height: 100%; */
  justify-content: space-between;
  align-items: center;
}

.ms_card_download_container {
  width: 36px;
  height: 36px;
  /* UI Properties */
  display: flex;

  justify-content: center;
  align-items: center;
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.ms_assets_cards_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
}

.ms_card_heading {
}

.ms_card_p {
  width: 80%;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
}
.zone_card_asset {
  border: 1px solid #eae4f2;
}

.assets_icons {
  font-size: 28px;
  margin-right: 4px;
}

.zone_card_download_btn {
  width: 36px;
  height: 36px;
}

.zone_card_links {
  font-size: 20px;
}

@media (max-width: 1000px) {
  .ms_img_container {
    width: 20%;
    height: 150px;
    border: 1px solid #cccccc;
    background-color: #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
}
