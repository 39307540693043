.rsp_bg {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/svgs/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.rsp_container {
  width: 450px;
  height: 390px;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 5px;
  padding: 10px;
}

.rs_btn {
  width: 170px;
  height: 41px;
  font-size: 18px;
  letter-spacing: 1px;
}

.resetpass_logo {
  margin-top: 90px;
}

.resetpass_input {
  height: 41px;
}

.rsp_container .ant-form > .ant-form-item .ant-form-item-label > label {
  font-size: 18px;
}
