/* Bug Resolved 1 */
.btn-svg:hover svg {
  fill: #ffffff !important;
  /* stroke = #ffffff */
}

.background-footer-create-customer-account {
  background-color: #fbfbfb;
}
.options {
  color: grey;
}
.options:hover {
  color: rgb(238, 37, 70);
}

.filter-logo {
  height: "17.02px";
  width: "16px";
}
.filter-logo:hover {
  background-color: #ef0855;
}

.button-pink {
  border: 2px solid #ef0855;
  background-color: white;
  color: #ef0855;
}
.button-pink:hover {
  background-color: #ef0855;
  color: white;
}

.button-pink-filter {
  background-color: #ef0855;
  color: white;
  border: none;
}
.button-pink-filter:hover {
  background-color: #ef0855;
  color: white;
  border: none;
}

.bg-button-green {
  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
}
.border-pink {
  border: 1px solid #ef0855;
}
.border-gray {
  border: 1px solid #e8e4ee;
}
.border-bottom-gray {
  border-bottom: 1px solid #e8e4ee;
}
.background-white-btn {
  background-color: white !important;
}
.social-media-div {
  /* height: 260px; */
  max-height: 300px;
  overflow-y: scroll;
}
.social-media-div::-webkit-scrollbar {
  display: none;
}
.image-upload {
  /* display : flex; */
  /* justify-content: center; */
  /* display : inline !important; */
}
.image-upload > input[type="file"]::-webkit-file-upload-button {
  display: none;
}
.image-upload > input {
  visibility: hidden;
}

.bg-button-green {
  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
}

.bg-button-red:hover {
  background-color: rgb(255, 215, 215) !important;
  border: 1px solid red !important;
  color: black !important;
}
.agents-error-margin-top {
  margin-top: 150px;
}
/* 
.Abc{
    max-height: 140px !important;
} */

/* .image-upload > input {
     d
 } */
/* .image-upload > input, button, select, optgroup, textarea {
    display: block;
    color : red;
}  */

.brand_input_color {
  width: 200px;
}

.spinner {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
