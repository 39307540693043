.social_chat_btn {
  width: 105px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/chat-red.svg"); */
  /* background-position: 10px; */
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_chat_btn:hover {
  width: 105px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/chat-white.svg"); */
  /* background-position: 10px; */
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_video_btn {
  width: 141px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/video-camera-red.svg"); */
  /* background-position: 10px; */
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_video_btn:hover {
  width: 141px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/video-white.svg"); */
  /* background-position: 10px; */
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_demo_btn {
  width: 225px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/video-camera-red.svg"); */
  /* background-position: 20px; */
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_demo_btn:hover {
  width: 225px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/video-white.svg"); */
  color: #ffffff;
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_booth_btn {
  width: 154px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-red.svg"); */
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  color: #ef0855;
  border-radius: 5px;
}

.social_booth_btn:hover {
  width: 154px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-white.svg"); */
  color: #ffffff;
  /* background-position: 10px; */
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_postBtn {
  width: 150px;
  height: 38px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_recent_sortBtn {
  width: 129px;
  height: 41px;
  /* UI Properties */
  border: 1px solid #e8e4ee;
  /* background-image: url("../../assets/svgs/sort-red.svg"); */
  color: #ef0855;
  /* background-position: 8px; */
  background-repeat: no-repeat;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 5px;
}

.social_recent_sortBtn:hover {
  width: 125px;
  height: 41px;
  /* UI Properties */
  border: 1px solid #e8e4ee;
  background-color: #ef0855;
  /* background-image: url("../../assets/svgs/sort-white.svg"); */
  color: #ffffff;
  /* background-position: 8px; */
  background-repeat: no-repeat;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 5px;
}

.social_like_sortBtn {
  width: 111px;
  height: 41px;
  /* UI Properties */
  border: 1px solid #e8e4ee;
  /* background-image: url("../../assets/svgs/sort-red.svg"); */
  color: #ef0855;
  /* background-position: 8px; */
  background-repeat: no-repeat;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 5px;
}

.social_like_sortBtn:hover {
  width: 111px;
  height: 41px;
  /* UI Properties */
  border: 1px solid #e8e4ee;
  /* background-image: url("../../assets/svgs/sort-red.svg"); */
  color: #ef0855;
  /* background-position: 8px; */
  background-repeat: no-repeat;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 5px;
}
.social_input_post {
  width: 45%;
}

.social_postlist {
  width: 55%;
}

.social_post_container {
  height: 400px;
  overflow-y: scroll !important;
  /* overflow: hidden; */
  margin-bottom: 30px;
}

.social_post_container::-webkit-scrollbar {
  display: none;
}

/* Track */
/* .social_post_container::-webkit-scrollbar-track {
  border-radius: 5%;

  margin-bottom: 30px;
  background: #f0f2f5;
} */
.social_post {
  width: 93%;
  height: 160px;
}

.social_heart {
  background-image: url("../../assets/svgs/heart-fill.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.social_edit {
  background-image: url("../../assets/svgs/edit-grey.svg");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  width: 20px;
  height: 20px;
}

.social_delete {
  background-image: url("../../assets/svgs/bin-grey.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.social_removeImg {
  width: 100px;
  height: 53px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_removeImg:hover {
  width: 100px;
  height: 53px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.social_yesImg {
  width: 112px;
  height: 53px;
  /* UI Properties */
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #ef0855;
  border: 1px solid #ef0855;

  border-radius: 5px;
}

.social_yesImg:hover {
  width: 112px;
  height: 53px;
  /* UI Properties */
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;

  border-radius: 5px;
}

.vista_social_container {
  height: 600px;
  overflow: auto;
}

.vista_social_container::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 12px;
}

@media (max-width: 1000px) {
  .social_bottom {
    flex-wrap: wrap;
  }
  .social_input_post {
    width: 100%;
  }
  .social_postlist {
    width: 100%;
  }
  .responsive_vista {
    font-size: 18px;
  }
}

.social_wall_image_modal {
  padding: 10px !important;
}
.pro_pic_review .ant-modal {
  /* max-width: 200px !important; */
  background-color: #ef0855 !important;
  width: 500px !important;
  padding: 20px;
}
