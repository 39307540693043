.expomap_sider {
  z-index: 3;
  padding: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 5px 0px 0px 5px;
}

.expomap_sider .ant-menu-item-selected {
  border-radius: 5px;
}

.expomap_sider .ant-menu-item {
  margin: 0px;
}

.expomap_sider_text {
  padding-top: 3px;
  margin-left: 7px;
}

.expomap_expohall_search {
  width: 290px;
  height: 35px;
  /* UI Properties */
  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.expomap_menu .ant-menu-item span {
  margin-left: 27px;
}

.expomap_list>.ant-menu-title-content>.expomap_link_list {
  margin-left: 0px;
}

.expomap_list:hover>.ant-menu-title-content>.expomap_link_list {
  color: white;
}

.expomap_list:hover>svg {
  fill: #ffffff;
}

.expomap_link_list {
  color: #000000;
}

.ant-menu-item-selected>.ant-menu-title-content>.expomap_link_list {
  color: #ffffff;
}

.vista_expomap_container {
  overflow: auto;
}

.vista_expomap_container::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 12px;
}

.remove_scroll_vista::-webkit-scrollbar {
  display: none;
}