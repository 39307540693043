.productZoneSession_section_1_form {
    margin-top: 40px;
    padding-right: 49px;
    height: auto;
  }
  
  .productZoneSession_row {
    margin-bottom: 30px;
    height: auto;
  }
  
  .productZoneSession_fields {
    display: flex;
    align-items: center;
  }
  
  .productZoneSession_label {
    display: flex;
    justify-content: flex-end;
  
    padding-right: 30px;
  
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
  }
  
  .productZoneSession_label p {
    height: 23px;
    margin-top: 3px;
  }
  
  .productZoneSession_date_time {
    width: 305px;
    height: 41px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_inputTitle {
    height: 41px;
    width: 100%;
    text-align: left;
    font: normal normal normal 16px/60px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.9;
  }
  
  .productZoneSession_inputDescription {
    text-align: left;
    font: normal normal normal 16px/60px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.9;
  }
  
  .productZoneSession_section_1_inputSeat {
    width: 100%;
  }
  
  .productZoneSession_section_1_downloadBtn {
    width: 170px;
    height: 41px;
    border: 1px solid var(--unnamed-color-ef0855);
    background: #ef0855 0% 0% no-repeat padding-box;
    white-space: nowrap;
    background-image: url("/src/assets/svgs/download.svg");
    background-position: 10px;
    padding-left: 1em;
    box-shadow: 0px 3px 20px #ef085533;
    border: 1px solid #ef0855;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_section_1_downloadBtn:hover {
    width: 170px;
    height: 41px;
    border: 1px solid var(--unnamed-color-ef0855);
    background: #ef0855 0% 0% no-repeat padding-box;
    white-space: nowrap;
    background-image: url("/src/assets/svgs/download.svg");
    background-position: 10px;
    padding-left: 1em;
    box-shadow: 0px 3px 20px #ef085533;
    border: 1px solid #ef0855;
    border-radius: 5px;
    opacity: 1;
  }
  
  /* Assign Staff */
  
  .productZoneSession_assignstaffs_form {
    margin-top: 30px;
    padding-right: 49px;
    padding-bottom: 30px;
    padding-left: 30px;
    border-bottom: 1px solid #eae4f2;
  
    height: auto;
  }
  
  .productZoneSession_assignstaffs_title {
    height: 29px;
    /* UI Properties */
    text-align: center;
  
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_assignstaffs_heading {
    height: 26px;
    /* UI Properties */
    text-align: left;
    font: normal normal medium 18px/60px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  
  .productZoneSession_assignstaffs_assigned_container {
    width: 100%;
    height: 245px;
    padding: 10px;
    /* UI Properties */
    /* background: #fbfbfb 0% 0% no-repeat padding-box; */
    /* border: 1px solid #e8e4ee; */
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_assignstaffs_select {
    width: 100%;
    height: 41px;
    /* UI Properties */
    /* overflow: auto; */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000001a;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_assignstaffs_options {
    /* height: 41px; */
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    font: normal normal normal 16px/23px Jost;
    letter-spacing: 0px;
    color: #000000;
  
    border-radius: 4px;
    opacity: 1;
  }
  
  .productZoneSession_assignstaffs_taggedagents_container {
    width: 100%;
    height: 245px;
    padding: 10px;
    /* UI Properties */
    display: flex;
    flex-direction: column;
  
    /* overflow-y: scroll; */
    background: #fbfbfb 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_assignstaffs_taggedagents_item {
    width: 100%;
    min-height: 41px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    margin-bottom: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 9.86px;
    padding-left: 10px;
    font: normal normal normal 16px/23px Jost;
    letter-spacing: 0px;
    color: #ef0855;
  
    opacity: 1;
  }
  
  .productZoneSession_inviteAgentsBtn {
    width: 180px;
    height: 41px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ef0855;
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
  
    /* background-image: url("/src/assets/svgs/addGroupRed.svg"); */
    background-position: 10px;
    color: #ef0855;
    border-radius: 5px;
    opacity: 1;
  }
  
  /* Media Assets */
  
  /*  Media Assets */
  .productZoneSession_media_assets_form {
    margin-top: 30px;
    padding-right: 49px;
    padding-bottom: 30px;
    padding-left: 30px;
    border-bottom: 1px solid #eae4f2;
  
    height: auto;
  }
  
  .productZoneSession_media_assets_title {
    width: 100%;
    height: 29px;
  
    text-align: left;
    font: normal normal medium 20px/16px Jost;
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_media_assets_info {
    display: inline-block;
    white-space: nowrap;
    font: normal normal normal 16px/22px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  
  .productZoneSession_media_assets_fileUploadBtn {
    width: 178px;
  
    height: 41px;
    /* UI Properties */
  
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ef0855;
    border-radius: 5px;
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ef0855;
  
    opacity: 1;
  }
  
  .productZoneSession_media_assets_fileUploadIcon {
    margin-right: 10px;
  }
  
  /* Restrict Access */
  .productZoneSession_restrict_access_form {
    margin-top: 30px;
    padding-right: 49px;
  
    height: auto;
  }
  
  .productZoneSession_restrict_access_title {
    width: 100%;
    height: 100%;
    padding-left: 30px;
    color: var(--unnamed-color-ef0855);
    text-align: left;
    font: normal normal medium 20px/16px Jost;
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_info {
    width: 100%;
    height: 100%;
    text-align: left;
    padding-left: 30px;
    font: normal normal normal 16px/22px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  
  .productZoneSession_selectUserList {
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_searchForUser {
    width: 100%;
    height: 41px;
    text-align: left;
    font: normal normal normal 16px/60px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
  }
  
  .productZoneSession_userListTitle {
    width: 100%;
    height: 26px;
    text-align: left;
    font: normal normal medium 18px/60px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_box1 {
    width: 100%;
    height: 245px;
    overflow-y: scroll;
    background: #fff1f5 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_list_container {
    padding: 10px;
    min-height: 61px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5px;
  }
  
  .productZoneSession_item_blacklist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 41px;
    padding: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 3px;
    opacity: 1;
    color: var(--unnamed-color-ef0855);
    text-align: left;
    font: normal normal normal 16px/23px Jost;
    letter-spacing: 0px;
    color: #ef0855;
  }
  
  .productZoneSession_item_whitelist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 41px;
    padding: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 3px;
    opacity: 1;
    font: normal normal normal 16px/23px Jost;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .productZoneSession_restrict_access_box2 {
    width: 100%;
    height: 245px;
    overflow-y: scroll;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_brand_box1 {
    width: 100%;
    height: 61px;
    overflow-y: scroll;
    background: #fff1f5 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_brand_box2 {
    width: 100%;
    height: 61px;
    overflow-y: scroll;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_box2 {
    width: 100%;
    height: 245px;
    overflow-y: scroll;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_country_box1 {
    width: 100%;
    height: 61px;
    overflow-y: scroll;
    background: #fff1f5 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_country_box2 {
    width: 100%;
    height: 61px;
    overflow-y: scroll;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_job_box1 {
    width: 100%;
    height: 61px;
    overflow-y: scroll;
    background: #fff1f5 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_restrict_access_job_box2 {
    width: 100%;
    height: 61px;
    overflow-y: scroll;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .noOneSelected {
    display: inline;
    color: #000000;
    margin: 0;
    opacity: 0.5;
  }
  
  /* Previous and Pending */
  .productZoneSession_prevpending_form {
    margin-top: 30px;
    padding-right: 49px;
  
    height: auto;
  }
  
  .productZoneSession_prevpending_title {
    width: 100%;
    height: 29px;
    padding-left: 30px;
    color: var(--unnamed-color-ef0855);
    text-align: left;
    font: normal normal medium 20px/16px Jost;
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_prevpending_previousInvitedBtn {
    width: 324px;
  
    height: 41px;
    background: #ef0855 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #ef085533;
    background-image: url("/src//assets/svgs/eye-line-white.svg");
    background-position: 20px;
    border: 1px solid #ef0855;
    border-radius: 5px;
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ffffff;
    white-space: nowrap;
    opacity: 1;
  }
  
  .productZoneSession_prevpending_previousInvitedBtn:hover {
    width: 324px;
  
    height: 41px;
    background: #ef0855 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #ef085533;
    background-image: url("/src//assets/svgs/eye-line-white.svg");
    background-position: 20px;
    border: 1px solid #ef0855;
    border-radius: 5px;
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ffffff;
    white-space: nowrap;
    opacity: 1;
  }
  
  .productZoneSession_eyeViewBtn {
    display: flex;
  
    position: relative;
  }
  
  .productZoneSession_eyeViewIcon {
    position: absolute;
    top: 10px;
    left: 22px;
  }
  
  .productZoneSession_prevpending_heading {
    height: 26px;
    text-align: left;
    font: normal normal medium 18px/60px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  
  .productZoneSession_date_time {
    width: 305px;
    height: 41px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_prevpending_viewListBtn {
    width: 159px;
    height: 41px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ef0855;
    border-radius: 5px;
    opacity: 1;
    position: relative;
    /* background-image: url("/src/assets/svgs/eye-line.svg"); */
    background-position: 20px;
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ef0855;
  }
  
  .productZoneSession_prevpending_viewListBtn:hover {
    width: 159px;
    height: 41px;
    background: #ef0855 0% 0% no-repeat padding-box;
    border: 1px solid #ef0855;
    border-radius: 5px;
    opacity: 1;
    position: relative;
    /* background-image: url("/src/assets/svgs/eye-line-white.svg"); */
    background-position: 20px;
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ffffff;
  }
  
  .productZoneSession_eyeViewIcon {
    position: absolute;
    top: 10px;
    left: 22px;
  }
  
  /* New Invitees */
  
  .productZoneSession_new_invitees_form {
    margin-top: 30px;
    padding-right: 49px;
  
    height: auto;
  }
  
  .productZoneSession_new_invitees_title {
    width: 100%;
    height: 29px;
    padding-left: 30px;
    color: var(--unnamed-color-ef0855);
    text-align: left;
    font: normal normal medium 20px/16px Jost;
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_new_invitees_heading {
    width: 125px;
    height: 26px;
    text-align: left;
  
    font: normal normal medium 18px/60px Jost;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  
  .productZoneSession_attendeesSelectedText {
    height: 23px;
    color: var(--unnamed-color-ef0855);
    text-align: left;
    font: normal normal normal 16px/22px Jost;
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_selectBrand {
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_selectZone {
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e4ee;
    border-radius: 5px;
    opacity: 1;
  }
  
  .productZoneSession_invitation_setup_form {
    margin-top: 30px;
    padding-right: 49px;
  
    height: auto;
  }
  
  .productZoneSession_invitation_setup_title {
    width: 100%;
    height: 29px;
    padding-left: 30px;
    color: var(--unnamed-color-ef0855);
    text-align: left;
    font: normal normal medium 20px/16px Jost;
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_whentosend_form {
    margin-top: 30px;
    padding-right: 49px;
  
    height: auto;
  }
  
  .productZoneSession_whentosend_title {
    width: 100%;
    height: 29px;
    padding-left: 30px;
    color: var(--unnamed-color-ef0855);
    text-align: left;
    font: normal normal medium 20px/16px Jost;
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_or {
    color: var(--unnamed-color-ef0855);
    text-align: right;
    font: normal normal normal 12px/60px Jost;
    letter-spacing: 0px;
    color: #ef0855;
    opacity: 1;
  }
  
  /* Modal Footer */
  .productZoneSession_cancelBtn {
    width: 90px;
    height: 41px;
    border: 1px solid var(--unnamed-color-ef0855);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ef0855;
    border-radius: 5px;
    color: var(--unnamed-color-ef0855);
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_cancelBtn:hover {
    width: 90px;
    height: 41px;
    border: 1px solid var(--unnamed-color-ef0855);
    background: #ef0855 0% 0% no-repeat padding-box;
    border: 1px solid #ef0855;
    border-radius: 5px;
    color: var(--unnamed-color-ef0855);
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ffffff;
    opacity: 1;
  }
  
  .productZoneSession_saveBtn {
    width: 250px;
    height: 41px;
    background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ef0855);
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #ef085533;
    border: 1px solid #ef0855;
    border-radius: 5px;
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ef0855;
    opacity: 1;
  }
  
  .productZoneSession_saveBtn:hover {
    width: 250px;
    height: 41px;
    background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ef0855);
    background: #ef0855 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #ef085533;
    border: 1px solid #ef0855;
    border-radius: 5px;
    font: normal normal medium 16px/16px Jost;
    letter-spacing: 0.8px;
    color: #ffffff;
    opacity: 1;
  }
  .MuiPopover-root{
    /* position: initial !important; */
    z-index: 1 !important;
  }
  