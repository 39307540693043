
table.zone_calendar.MuiTable-root:nth-child(1){
    display: none !important;
}

/* .zone_calendar>.MuiTable-root>.MuiTableBody-root>.MuiTableRow-root:nth-child(1){
    display: none !important;
} */
.MuiTableCell-root >div{
    text-align: center !important;
    /* color: black !important; */
    }
    .MuiTableCell-root >div>div{
        /* text-align: center !important; */
        color: black !important;
        }
.MuiTableCell-root .MuiTableCell-body .makeStyles-cell-35 .makeStyles-cell-47 .makeStyles-horizontalCell-37{
    text-align: center !important;
}
