.ant_rate .ant-rate-star:not(:last-child) {
  margin-right: 0px;
}

.feedback_container {
  min-height: 75vh;
}

.emptyColor {
  width: 3rem;
  height: 23px;
  background-color: #ffffff;
  border: 1px solid grey;
  border-radius: 3px 0px 0px 3px;
  opacity: 0.2;
}

.color_1 {
  width: 3rem;
  height: 23px;
  background-color: #ef0855;
  border-radius: 3px 0px 0px 3px;
  opacity: 0.2;
}

.color_2 {
  width: 3rem;
  height: 23px;
  background: #ef0855 0% 0% no-repeat padding-box;
  border-radius: 3px 0px 0px 3px;
  opacity: 0.4;
}

.color_3 {
  width: 3rem;
  height: 23px;
  background: #ef0855 0% 0% no-repeat padding-box;
  border-radius: 3px 0px 0px 3px;
  opacity: 0.6;
}

.color_4 {
  width: 3rem;
  height: 23px;
  background: #ef0855 0% 0% no-repeat padding-box;
  border-radius: 3px 0px 0px 3px;
  opacity: 0.8;
}

.color_5 {
  width: 3rem;
  height: 23px;
  background: #ef0855 0% 0% no-repeat padding-box;
  border-radius: 3px 0px 0px 3px;
  opacity: 1;
}

.feedback_numbers {
  width: 3rem;
  height: 23px;
}

.feedback_submitBtn {
  width: 5rem;
  height: 41px;
  /* UI Properties */

  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 20px #ef085533; */
  border: 1px solid #ef0855;
  color: #ef0855;
  border-radius: 5px;
}

.feedback_submitBtn:hover {
  width: 5rem;
  height: 41px;
  /* UI Properties */

  background: #ef0855 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 20px #ef085533; */
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .emptyColor {
    width: 2rem;
  }

  .color_1 {
    width: 2rem;
  }
  .color_2 {
    width: 2rem;
  }
  .color_3 {
    width: 2rem;
  }
  .color_4 {
    width: 2rem;
  }
  .color_5 {
    width: 2rem;
  }
}
