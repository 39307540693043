.border-pink {
  border: 1px solid #ef0855;
  color: #ef0855;
}
.border-pink:hover {
  background-color: #ef0855 !important;
  color: white;
}
.assets-scroll-div {
  height: 250px;
  overflow-y: scroll;
}
.assets-scroll-div::-webkit-scrollbar {
  display: none;
}
.agent-dropdown {
  z-index: 0 !important;
}

.MuiPopover-paper {
  width: 950px !important;
  height: 85vh;
  top: 10% !important;
  left: 20% !important;
}

.width-file_upload {
  width: 170px;
}
