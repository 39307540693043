/* .gravit8_header_menu_item > .ant-menu .ant-menu-item {
  padding-left: 0px;
}

.gravit8_header_menu_item_link {
  margin-left: auto;
} */

.gravit8_header_menu_item>.ant-menu-item span {
  margin-left: 10px;
}

.header_events_list {
  width: 281px;
}