.conf-table-container {
  margin: auto;
  width: fit-content;
}

.left_netTable {
  width: 20%;
  min-height: 60vh;
  padding-top: 10px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 5px 0px 0px 5px;
  opacity: 1;
}

.right_netTable {
  width: 70%;
}

.sponsored_netTable_img {
  width: 200px;
  height: 200px;
}

.netTable_btn {
  width: 219px;
  height: 41px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  border-radius: 5px;
  opacity: 1;
  margin-bottom: 7px;
}
.netTable_profile {
  cursor: pointer;
}
.netTable_profile_header {
  height: 65px;
}

.neTTable_profile_userData {
  width: 20%;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  opacity: 1;
}
