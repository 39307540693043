.zone-tag-scroll-div {
  max-height: 300px;
  overflow-y: scroll;
  margin-left: 12px;
  z-index: 0;
}
.zone-tag-scroll-div::-webkit-scrollbar {
  display: none;
}
.user-profile-picture {
  max-height: 60px;
  max-width: 60px;
  border-radius: 50% !important;
}
.user-img-preview {
  max-height: 60px;
  max-width: 60px;
  margin-left: 5px;
}

.prof_user_btn {
  height: 37px;
}
