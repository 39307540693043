/* .square_div {
    width : 30px !important;
    background-color: blue; 
} */

[data-theme="compact"] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 60px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}

.customer-management-form .ant-steps.ant-steps-navigation {
  padding-top: 0px;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 10%) !important;
  -webkit-box-shadow: 0px 2px 10px 3px rgb(0 0 0 / 10%) !important;
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1) !important;
}

.customer-management-form .ant-steps.ant-steps-navigation .ant-steps-item {
  padding-top: 12px;
  border: 1px solid #eae4f2;
}

.customer-management-form
  .ant-steps.ant-steps-navigation
  .ant-steps-item::after {
  display: none;
}

.customer-management-form
  .ant-steps.ant-steps-navigation
  .ant-steps-item
  .ant-steps-item-container {
  text-align: center;
  width: 100%;
}

.customer-management-form
  .ant-steps.ant-steps-navigation
  .ant-steps-item
  .ant-steps-item-container
  .ant-steps-item-icon {
  display: block;
  margin: auto;
}

/* .ant-steps-item-active .ant-steps-item-icon {
  background: #ef0855 !important;
  max-width: 33px !important;
  color: #ffffff;
  height: 33px;
  border-radius: 100%;
} */

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #ffffff;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #000000;
}

.ant-steps-item-process .ant-steps-item-icon {
  border: none;
  color: black;
}

/* .ant-steps-icon {
  color: #ffffff;
} */

/* .ant-steps-item-wait {
  background-color: #ffffff;
  border: crimson !important;
} */
/* .font-pink {
  color: rgb(238, 37, 70);
} */
.background-color-gray {
  background-color: #fbfbfb !important;
}

/* .steps-circle-number {
  width: 50;
  height: 50;
  border-radius: 25;
  background-color: "#EF0855" !important;
  color: #ffffff;
} */
