@import "~antd/dist/antd.less";

/* //main container */
.hJzUFe {
  background: #f5f8fb;
  border-radius: 10px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 15%);
  font-family: Helvetica Neue;
  overflow: hidden;
  position: absolute;
  bottom: 10px;
  top: initial;
  right: 10px;
  left: initial;
  width: 350px;
  height: 520px;
  z-index: 999;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}

/* //header */
.jqSBBM {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ef0855;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  fill: #fff;
  height: 56px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
}

.kbDkhC {
  margin: 0;
  font-size: 15px;
  color: white
}

/* //content */
.cWBQNw {
  height: calc(520px - 112px);
  overflow-y: scroll;
  margin-top: 2px;
  padding-top: 6px;
}

/* //footer  */
.cyCRYR {
  position: relative;
  padding-top: 20px;
}

/* //content chya aat
  //left message */
.fkizOu {
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

/* //left messge div */

.hAEwKD {
  display: inline-block;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  padding: 6px;
}

/* left message inside div */
.cftzIa {
  -webkit-animation: gZmgDU 0.3s ease forwards;
  animation: gZmgDU 0.3s ease forwards;
  border-radius: 50% 50% 0 50%;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 2px 0px;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
  width: 40;
}

/* //left message text */
.jzwenc {
  -webkit-animation: gZmgDU 0.3s ease forwards;
  animation: gZmgDU 0.3s ease forwards;
  background: #ef0855;
  border-radius: 18px 18px 18px 0;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  color: #fff;
  display: inline-block;
  font-size: 14px;
  max-width: 50%;
  margin: 0 0 10px 0;
  overflow: hidden;
  position: relative;
  padding: 12px;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

/* //right message */
.ZfeSB {
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/* //right messgae icon div */
.hnGEZW {
  display: inline-block;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  padding: 6px;
}

/* //right mesgg icon image inside div */
.cXLLpN {
  -webkit-animation: gZmgDU 0.3s ease forwards;
  animation: gZmgDU 0.3s ease forwards;
  border-radius: 50% 50% 50% 0;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 2px 0px;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  width: 40;
}

/* //right messg text */
.iXUvls {
  -webkit-animation: gZmgDU 0.3s ease forwards;
  animation: gZmgDU 0.3s ease forwards;
  background: #fff;
  border-radius: 18px 18px 0 18px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  color: #4a4a4a;
  display: inline-block;
  font-size: 14px;
  max-width: 50%;
  margin: 0 0 10px 0;
  overflow: hidden;
  position: relative;
  padding: 12px;
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
}

/* //inside footer 2 components */
/* Type the message ... */
/* <input> */
/* //button */
.biRHyV {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: default;
  fill: #4a4a4a;
  opacity: 0.5;
  outline: none;
  // padding: 14px 16px 12px 16px;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 22px;
}
@primary-color: #EF0855;