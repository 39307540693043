.survey_container {
  height: 50vh;
  margin-top: 10px;

  padding-left: 12%;
  padding-top: 20px;
}

.option_block {
  min-width: 15%;
}
