@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", "Courier New", Courier, monospace;
  font-size: 16px;
}

.h-10 {
  height: 10px;
}

.h-55 {
  height: 55px;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border_except_top {
  border-bottom: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}

.cursor-pointer {
  cursor: "pointer";
}

/* ------- font-size ------- */
.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-bold-14 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-bold-16 {
  font-size: 16px;
  font-weight: 500 !important;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-pink {
  color: var(--primary-color);
}

.pl-2 {
  padding-left: 2%;
}

.mr-3 {
  margin-right: 3%;
}

.table-striped .ant-table-tbody > tr.ant-table-row:nth-child(2n + 1) > td {
  background: #fafafa;
}

.table-striped .ant-table-thead > tr > th {
  background: unset;
}

.ant-layout-sider.ant-layout-sider-dark.bg-light.sidebar-div {
  position: fixed;
  height: 100vh !important;
}

.ant-layout.site-layout {
  margin-left: 200px;
}

.ant-layout-header.site-layout-background {
  position: sticky;
  top: 0;
  z-index: 99;
}

.ml-200px {
  margin-left: 200px !important;
}

.ml-80px {
  margin-left: 80px !important;
}

.btn-svg:hover svg {
  fill: #ffffff !important;
  /* stroke = #ffffff */
}

.btn-svg:hover svg g {
  fill: white;
}

.ant-input {
  font-weight: 500;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.bg-button-green {
  width: 80px;
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;

  background-color: #4dcc591a;
  border: 1px solid #4dcc59;
}

.bg-button-green:hover {
  background-color: #4dcc591a !important;
  border: 1px solid #4dcc59 !important;
  color: black !important;
}

.bg-button-red {
  width: 80px;
  height: 32px;
  padding: 4px 16px;
  font-size: 13px;
  border-radius: 32px;
  background-color: rgb(255, 215, 215) !important;

  border: 1px solid #ef0855;
}

.bg-button-red:hover {
  background-color: rgb(255, 215, 215) !important;
  border: 1px solid red !important;
  color: black !important;
}

.bg-button-green:focus {
  background-color: #4dcc591a !important;
  border: 1px solid #4dcc59 !important;
}

.bg-button-red:focus {
  background-color: rgb(255, 215, 215) !important;
  border: 1px solid #ef0855 !important;
  color: #ef0855 !important;
}

.social-media-div {
  /* height: 260px; */
  max-height: 300px;
  overflow-y: scroll;
}

.social-media-div::-webkit-scrollbar {
  display: none;
}

.image-upload > input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.view-all-common .ant-switch-disabled {
  opacity: 1;
}

.view-all-common .ant-input[disabled] {
  background-color: white;
  color: black;
}

.view-all-common .ant-picker.ant-picker-disabled {
  background-color: white;
  color: black;
}

.view-all-common .ant-picker.ant-picker-disabled input {
  /* background-color: white; */
  color: black;
}

.view-all-common
  .ant-select-disabled
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: white !important;
  color: black;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: white !important;
  color: black !important;
}

.rc-virtual-list-holder {
  max-height: 137px !important;
}

.ant-select-selection-placeholder {
  color: black;
  font-weight: 400 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: white;
}

/* .ant-select-item-option {
  color: var(--primary-color);
}

.ant-select-item-option-active:hover {
  background-color: #4dcc59;
} */

.ant-modal-body {
  padding: 0px;
}

.ant-input-group-addon {
  font-weight: unset;
}

.ant-picker-input > input {
  font-weight: 500;
}

.ant-drawer-left {
  margin-left: 200px;
  margin-top: 20px;
}

.ant-modal-footer {
  background-color: #fbfbfb !important;
  display: flex;
  justify-content: space-between;
}

.ant-modal-header {
  background-color: #fbfbfb !important;
  padding: 30px 0px 30px 30px;
}

.ant-modal-title {
  color: #ef0855;
}

/* -------------Dev Express Calendar Classes Manupulation -------------------*/

.MuiPopover-paper {
  width: 950px !important;
  position: relative;
  top: 20% !important;
  left: 20% !important;
  height: auto !important;
}

.Layout-popover-392 {
  width: 600px !important;
  position: relative;
  top: 20% !important;
  left: 40% !important;
}

.Cell-highlightedText-35 {
  color: #ef0855 !important;
}

.Cell-highlightedText-31 {
  color: #ef0855 !important;
}

.MuiButton-outlined {
  border: 1px solid #ef0855 !important;
}

.Header-head-413 {
  min-height: 0 !important;
  padding-top: 0 !important;
}

.makeStyles-textCenter-422 {
  height: 0 !important;
}

/* .makeStyles-text-417 {
  display : none !important
}
.makeStyles-icon-419 {
  display : none !important
} */
.makeStyles-contentContainer-425 {
  padding: 0 !important;
}

.makeStyles-titleContainer-424 {
  padding: 0 !important;
}

.MuiGrid-root .MuiGrid-item > div {
  display: none !important;
  height: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

/* .MuiGrid-root .MuiGrid-item > div > div:nth-child(2){
  display : none !important;
  height: 0 !important;
} */
/* .MuiGrid-root .MuiGrid-item > div > svg {
  display: none;
  height: 0 !important;
}
*/
.MuiGrid-root .MuiGrid-item > svg {
  display: none;
  height: 0 !important;
}

/* .MuiGrid-root .MuiGrid-item > div {
  display : none !important;
  background-color: #ef0855 !important;
  height: 0 !important;
} */
.MuiTableCell-root .MuiTableCell-body {
  color: green !important;
}

.active_btns {
  width: 80px;
  border: 1px solid #4dcc59 !important;
  color: #4dcc59 !important;
  background-color: #4dcc591a !important;
}

.active_btns:hover {
  width: 80px;
  border: 1px solid #4dcc59 !important;
  color: #4dcc59 !important;
  background-color: #4dcc591a !important;
}

.inactive_btns {
  color: #ef0855 !important;
}

.ant-form-item-explain div {
  text-align: start;
}

.svg_bin:hover {
  fill: #ef0855;
}

.svg_editred:hover {
  fill: #ef0855;
}

.bg-80vh {
  min-height: 80vh;
}

.cursor-pointer {
  cursor: pointer;
}

.fileupld_height {
  min-height: 34px;
  white-space: nowrap;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1400px) {
  .fileupld_height {
    font-size: 13px;
  }
}
