.bg-pink {
  /* background-color: rgb(238, 37, 70); */
  /* min-height: 100vh; */

  background-color: #ef0855;
  border-radius: 0.25rem;
  /* background-image: linear-gradient(to top, rgb(238, 10, 48), rgb(204, 136, 136)); */
}

.bg-pink:hover {
  background-color: #bb0844;
}

.login-bg-img-div {
  background-image: url("../../assets/svgs/BG_transparent.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center center;
  /* min-height: 750px; */
}

.login_img_logo {
  width: 240px;
  height: 54px;


}

.vista__bg {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.vista__iframe {
  width: 100%;
  height: 100%;
  border: "none";
}

.login_passInput .anticon-eye svg path {
  fill: #ef0855
}

.login_temp {
  position: absolute;
  top: 0;
  z-index: 999;
}