.people_inviteBtn {
  width: 179px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  /* UI Properties */
  letter-spacing: 0.8px;
  color: #ef0855;
  opacity: 1;
}

.people_inviteBtn:hover {
  width: 179px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  /* UI Properties */
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
}

.people_container {
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 5px;
  opacity: 1;
}

.search_container {
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #eae4f2;
  border-radius: 5px 5px 0px 0px;
}

.people_applyBtn {
  width: 105px;
  height: 41px;
  /* UI Properties */
  background: var(--unnamed-color-ef0855) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #ef085533;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.people_clearBtn {
  width: 101px;
  height: 41px;
  /* UI Properties */

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.people_clearBtn:hover {
  width: 101px;
  height: 41px;
  /* UI Properties */

  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
  opacity: 1;
}

.people_selectInput {
  width: 297px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.people_profile_picture {
  width: 42px;
  height: 42px;
}

.people_table_bg {
  background-color: #ffffff;
}

.people_right_container {
  width: 100%;
  /* background-color: #ffffff; */
}

.people_block {
  width: 220px;
  height: 86px;
  padding: 10px;
  justify-content: center;
  background-color: #fbfbfb;
  border: 1px solid #eae4f2;
}

.people_chatBtn {
  width: 105px;
  height: 41px;
  /* UI Properties */
  letter-spacing: 0.8px;
  color: #ef0855;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  background-image: url("../../assets/svgs/chat-red.svg");
  background-position: 10px;
}

.people_chatBtn:hover {
  width: 105px;
  height: 41px;
  /* UI Properties */
  letter-spacing: 0.8px;
  color: #ffffff;
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
  background-image: url("../../assets/svgs/chat-white.svg");
  background-position: 10px;
}

.people_videoBtn {
  width: 152px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.people_videoBtn {
  width: 152px;
  height: 41px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  color: #ef0855;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url("../../assets/svgs/video-camera-red.svg");
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.people_videoBtn:hover {
  width: 152px;
  height: 41px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  background-image: url("../../assets/svgs/video-white.svg");
  background-position: 10px;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.people_bookMeetingBtn {
  width: 184px;
  height: 41px;
  /* UI Properties */

  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ef0855;
  color: #ef0855;

  border-radius: 5px;
  background-image: url("../../assets/svgs/calendarRed.svg");
  background-position: 10px;
}

.people_bookMeetingBtn:hover {
  width: 184px;
  height: 41px;
  /* UI Properties */

  border: 1px solid var(--unnamed-color-ef0855);
  background: #ef0855 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: 1px solid #ef0855;
  border-radius: 5px;
  background-image: url("../../assets/svgs/calendar-white.svg");
  background-position: 10px;
}

.people_dropdown_upBtn {
  background-color: #fff1f5;
  color: #ef0855;
}

people_dropdown_downBtn {
  color: "green";
}

.ant-table-expanded-row .ant-table-cell {
  background-color: #ffffff;
}

.people_bio {
  height: 80px;
}
