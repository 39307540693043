.networking_event_search {
  width: 290px;
  height: 35px;
  /* UI Properties */

  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  opacity: 1;
}

.join_table {
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ant-carousel .networking_carousel {
  width: 206px;
  height: 41px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin-left: 260px;
  background-color: #ffffff;
  position: relative;
}

.networking_carousel .slick-slide {
  min-width: 1vw;
}

.ant-carousel .networking_card_carousel {
  height: 180px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  position: relative;
}

.ant-carousel .networking_carousel .slick-dots {
  display: none !important;
  top: 0;
}

.ant-carousel .networking_card_carousel .slick-dots {
  display: none !important;
  top: 0;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  opacity: 0.5;
}

.networking_prev_arrow {
  position: absolute;
  top: 7px;
  z-index: 10;
  cursor: pointer;
}

.networking_next_arrow {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;

}


.ant-carousel .networking_card_carousel .networking_prev_arrow {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 65px;
  z-index: 10;
}

.ant-carousel .networking_card_carousel .networking_next_arrow {
  display: inline;
  cursor: pointer;
  position: absolute;
  top: 65px;
  right: 3px;
  z-index: 10;
}


.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.networking_container {
  overflow-x: auto;
}

.networking_left_container {
  min-height: 60vh;
  height: auto;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 5px 0px 0px 5px;
  opacity: 1;
}

.networking_col {
  width: 1000px;
}

.networking_img {
  width: 219px;
  height: 219px;
  /* UI Properties */
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
}

.networking_pdfBtn {
  width: 219px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 30px; */
  color: #ef0855;

  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.networking_pdfBtn:hover {
  width: 219px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 30px; */

  color: #ffffff;
  border: 1px solid #eae4f2;
  border-radius: 5px;
}

.networking_search {
  width: 320px;
  height: 41px;
  /* UI Properties */
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #e8e4ee;
  border-radius: 5px;
  padding-top: 3px;
  padding-left: 20px;
  color: #0000000;
  opacity: 0.5;
}

.networking_date {
  width: 320px;
  height: 43px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ef0855);
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #ef0855;
  border: 1px solid #ef0855;
  border-radius: 5px;
}

.networking_join_sessionBtn {
  width: 206px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-red.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.networking_join_sessionBtn:hover {
  width: 206px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/booth-white.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.networking_downloadBtn {
  width: 150px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/downloadRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.networking_downloadBtn:hover {
  width: 150px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/download.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

.networking_calendarBtn {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendarRed.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ef0855;
  border-radius: 5px;
}

.networking_calendarBtn:hover {
  width: 200px;
  height: 41px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  /* background-image: url("../../assets/svgs/calendar-white.svg"); */
  /* background-position: 20px; */
  border: 1px solid #ef0855;
  letter-spacing: 0.8px;
  color: #ffffff;
  border-radius: 5px;
}

networking_card_img {
  width: 20px;
  height: 20px;
}

.networking_session {
  width: 90%;
  height: 90px;
  /* UI Properties */
  background: #ef0855 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.7;
}

/* .networking_sideImg {
  
} */

#net_tooltip::-webkit-scrollbar {
  width: 7px;
}

/* Track */
#net_tooltip::-webkit-scrollbar-track {
  border-radius: 5%;
  height: 100%;
  margin-bottom: 20px;
  background: #ef0855;
}

.networking_tooltipContent {
  background-color: #ef0855;
  color: #ffffff;
}

/* #net_tooltip {
  border-right: 5px solid #ef0855;
  
} */

.ant-tooltip-inner {
  background-color: #ef0855;
}

.net_search_input {
  height: 41px;
}

.net_options {
  width: 100%;
  min-height: 30px;
  padding: 10px;
}